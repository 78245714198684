import React, { useRef, useEffect } from 'react';
import {
  Editor,
  EditorProvider,
  BtnBold,
  BtnItalic,
  BtnUnderline,
  BtnStrikeThrough,
  BtnLink,
  BtnUndo,
  BtnRedo,
  HtmlButton,
  Separator,
  Toolbar,
  BtnBulletList,
  createButton,
} from 'react-simple-wysiwyg';

const TextEditor = ({ value, onChange, name }) => {
  const wrapperRef = useRef(null);

  // Функція для фокусування редактора
  const focusEditor = () => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      const contentEditable = wrapper.querySelector('[contenteditable="true"]');
      if (contentEditable) {
        contentEditable.focus();
      }
    }
  };

  // Викликаємо фокус при кліку на кнопки форматування
  const handleButtonClick = (action) => () => {
    focusEditor();
    action();
  };

  // Кастомна кнопка: Вирівнювання по центру
  const BtnAlignCenter = createButton(
    'AlignCenter',
    '📞',
    ({ $selection }) => {
      if ($selection?.nodeName === 'A') {
        document.execCommand('unlink');
      } else {
        const url = prompt('Телефон("tel:" НЕ ПРИБИРАТИ)', 'tel:');
        if (url) {
          document.execCommand('createLink', false, url);
        }
      }
    }
  );

  // Кастомні кнопки: Соцмережі з використанням зображень розміром 30x30 та вставкою посилання
  const BtnFacebook = createButton(
    'Facebook',
    <img src="/images/facebook.png" alt="Facebook" width={24} height={24} style={{padding:4}}/>,
    () => {
      const url = prompt('Введіть URL Facebook', 'https://facebook.com/');
      if (url) {
        const html = `<a href="${url}" target="_blank" rel="noopener noreferrer"><img src="/images/facebook.png" alt="Facebook" width="30" height="30" /></a>`;
        document.execCommand('insertHTML', false, html);
      }
    }
  );

  const BtnInstagram = createButton(
    'Instagram',
    <img src="/images/instagram.png" alt="Instagram" width={24} height={24} style={{padding:4}}/>,
    () => {
      const url = prompt('Введіть URL Instagram', 'https://instagram.com/');
      if (url) {
        const html = `<a href="${url}" target="_blank" rel="noopener noreferrer"><img src="/images/instagram.png" alt="Instagram" width="30" height="30" /></a>`;
        document.execCommand('insertHTML', false, html);
      }
    }
  );

  const BtnYouTube = createButton(
    'YouTube',
    <img src="/images/youtube.png" alt="YouTube" width={24} height={24} style={{padding:4}}/>,
    () => {
      const url = prompt('Введіть URL YouTube', 'https://youtube.com/');
      if (url) {
        const html = `<a href="${url}" target="_blank" rel="noopener noreferrer"><img src="/images/youtube.png" alt="YouTube" width="30" height="30" /></a>`;
        document.execCommand('insertHTML', false, html);
      }
    }
  );

  // Обробник вставки
  const handlePaste = (e) => {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  };

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      const contentEditable = wrapper.querySelector('[contenteditable="true"]');
      if (contentEditable) {
        contentEditable.addEventListener('paste', handlePaste);
      }

      // Очистка обробника при размонтуванні компонента
      return () => {
        if (contentEditable) {
          contentEditable.removeEventListener('paste', handlePaste);
        }
      };
    }
  }, []);

  // Обробник зміни контенту редактора
  const handleEditorChange = (newContent) => {
    if (typeof onChange === 'function') {
      onChange(newContent);
    }
  };

  return (
    <div className='Editor' ref={wrapperRef}>
      <EditorProvider>
        <Editor
          name={name}
          value={value}
          onChange={handleEditorChange}
        >
          <Toolbar>
            <BtnUndo />
            <BtnRedo />
            <Separator />
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <BtnStrikeThrough />
            <Separator />
            <BtnLink />
            <BtnAlignCenter />
            <Separator />
            <BtnBulletList />
            <Separator />

            <BtnFacebook />
            <BtnInstagram />
            <BtnYouTube />
            <Separator />
            <HtmlButton />
          </Toolbar>
        </Editor>
      </EditorProvider>
    </div>
  );
};

export default TextEditor;
