
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import url from '../../url';

import DatePicker from "react-datepicker";
import TimePicker from 'react-time-picker';

import "react-datepicker/dist/react-datepicker.css";
import 'react-time-picker/dist/TimePicker.css';
import TextEditor from '../../components/TextEditor';


function NewsUpdate() {

  const notificationId = useLocation().pathname.split("/")[3]

  const [notification, setNotification] = useState({
    title: "",
    text: "",
    time: ""
  });

  const [startDate, setStartDate] = useState(new Date());
  const [time, setTime] = useState('10:00');

  useEffect(() => {
    const fecthAllnotifications = async () => {
      try {
        const res = await axios.get(url + '/podii/' + notificationId, {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        })
        setNotification(res.data[0])
        const dateArr = res.data[0].time.split(' ')[0].split('/')
        setTime(res.data[0].time.split(' ')[1])
        setStartDate(new Date(dateArr[1] + '/' + dateArr[0] + '/' + dateArr[2]))
      } catch (err) {
        console.log(err)
      }
    }
    fecthAllnotifications()
  }, [notificationId])

  const HandleChange = (e) => {
    setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const navigate = useNavigate();

  const handleClick = async e => {
    e.preventDefault()
    notification.time = startDate.toLocaleString('en-GB', { hour12: false, }).split(',')[0] + ' ' + time;
    try {
      await axios.put(url + "/podii/" + notificationId, notification, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
      navigate('/NewsPodii/Podii')
    } catch (err) {

    }
  }

  function onChange(e) {
    setNotification(prev => ({ ...prev, ["text"]: e.target.value }))
  }
  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
        <button onClick={handleClick} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Оновити</button>
      </div>
      <div className='ImputWrapper'>Заголовок
        <input onChange={HandleChange} name="title" value={notification?.title}></input>

      </div>
      <div className='TimePickerWrapper'>
        <div className='ImputWrapper' style={{ width: 225 }}>Дата проведення
          <DatePicker dateFormat={'dd/MM/yyyy'} selected={startDate} onChange={(date) => setStartDate(date)} />
        </div>
        <div className='TimeWrapper'>Час початку
          <TimePicker disableClock={true} onChange={setTime} value={time} />
        </div>
      </div>
      <div className='ImputWrapper'>Текст події
        <TextEditor
          value={notification.text}
          onChange={onChange}
        />

      </div>
    </div>
  )
}

export default NewsUpdate
