import axios from 'axios';
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import url from '../../url';
import Cookies from 'js-cookie';
import TextEditor from '../../components/TextEditor';



function NotificationsAdd() {
  const [notification, setNotification] = useState({
    title: "",
    text: "",
  });
  const HandleChange = (e) => {
    setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const navigate = useNavigate();
  
  const handleClick = async e => {
    e.preventDefault()
    try {
      await axios.post(url + "/notifications", {
        title: notification.title,
        text: notification.text.replaceAll("<div><br></div>",'<br>')
      }, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      }
      );
      navigate('/Notifications')
    } catch (err) {

    }
  }

  const [text, setText] = useState('');
  const [link, setLink] = useState('');
  const inputRef = useRef(null);

  const handleTextChange = (newText) => {
    setNotification(prev => ({ ...prev, text: newText.target.value }));
  };
  function onChange(e) {
    setNotification(prev => ({ ...prev, ["text"]: e.target.value }))
  }



  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
        <button onClick={handleClick} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Зберегти</button>
      </div>
      <div className='ImputWrapper'>Заголовок
        <input onChange={HandleChange} name="title"></input>
      </div>
      <div className='ImputWrapper'>Текст сповіщення


        {/* <textarea onBlur={HandleChange} name="text" cols="40" rows="5"></textarea> */}
        {/* <div className='buttonTextWraper'>
          <button style={{ fontWeight: 800 }} onClick={() => wrapSelectedText('strong')}>Bold</button>
          <button style={{ fontStyle: "italic" }} onClick={() => wrapSelectedText('em')}>Italic</button>
          <button style={{ textDecoration: "Underline" }} onClick={() => wrapSelectedText('u')}>Underline</button>
          <button onClick={() => wrapSelectedText('span')}>Wrap with span</button>
          <button onClick={() => wrapSelectedText('a')}>Link</button>
          <button onClick={() => wrapSelectedText('phone')}>Phone</button>

          <input value={link} onChange={(e) => setLink(e.target.value)} style={{ width: 300, height: 24, minHeight: 0 }}></input>
        </div> */}
        {/* <textarea
          ref={inputRef}
          type="text"
          value={text}
          onChange={(e) => setText(e.target.value)}
        /> */}
        <TextEditor
          value={notification.text}
          onChange={handleTextChange}
        />

        {/* <iframe className='HtmlWrapper' srcdoc={"<html><style>body {font-size: 18px;}</style><body >" + text.replaceAll('\n', '</br>') + "</body></html>"}></iframe> */}


      </div>
    </div>
  )
}

export default NotificationsAdd
