
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import url from '../../url';
import TextEditor from '../../components/TextEditor';



function NewsUpdate() {

  const notificationId = useLocation().pathname.split("/")[3]

  const [notification, setNotification] = useState({
    title: "",
    text: "",
  });

  useEffect(() => {
    const fecthAllnotifications = async () => {
      try {
        const res = await axios.get(url + '/news/' + notificationId, {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        })
        setNotification(res.data[0])
      } catch (err) {
        console.log(err)
      }
    }
    fecthAllnotifications()
  }, [notificationId])

  const HandleChange = (e) => {
    setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const navigate = useNavigate();

  const handleClick = async e => {
    e.preventDefault()
    try {
      await axios.put(url + "/news/" + notificationId, {...notification, text: notification.text.replaceAll("<div>",'').replaceAll("</div>",'<br>').replaceAll("<br><br><br>",'<br><br>')}, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
      navigate('/NewsPodii')
    } catch (err) {

    }
  }


  function onChange(e) {
    setNotification(prev => ({ ...prev, ["text"]: e.target.value }))
  }


  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
        <button onClick={handleClick} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Оновити</button>
      </div>
      <div className='ImputWrapper'>Заголовок
        <input onChange={HandleChange} name="title" value={notification?.title}></input>

      </div>
      <div className='ImputWrapper'>Текст новини
        {/* <textarea onChange={HandleChange} name="text" cols="40" rows="5" value={notification?.text}>
        </textarea> */}
        <TextEditor
          value={notification.text}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default NewsUpdate
