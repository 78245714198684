
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import url from '../../url';

import TextEditor from '../../components/TextEditor';


function NotificationsUpdate() {

  const notificationId = useLocation().pathname.split("/")[3]

  const [notification, setNotification] = useState({
    title: "",
    text: "",
  });

  useEffect(() => {
    const fecthAllnotifications = async () => {
      try {
        const res = await axios.get(url + '/notifications/' + notificationId, {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        })
        setNotification(res.data[0])
      } catch (err) {
        console.log(err)
      }
    }
    fecthAllnotifications()
  }, [notificationId])

  const HandleChange = (e) => {
    setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const navigate = useNavigate();

  const handleClick = async e => {
    e.preventDefault()
    try {
      await axios.put(url + "/notifications/" + notificationId, {
        title: notification.title,
        text: notification.text.replaceAll("<div><br></div>",'<br>')
      }, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
      navigate('/Notifications')
    } catch (err) {

    }
  }

  const handleTextChange = (newText) => {
    setNotification(prev => ({ ...prev, text: newText.target.value }));
  };
  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
        <button onClick={handleClick} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Оновити</button>
      </div>
      <div className='ImputWrapper'>Заголовок
        <input onChange={HandleChange} name="title" value={notification?.title}></input>

      </div>
      {/* <div className='ImputWrapper'>Текст сповіщення
        <textarea onChange={HandleChange} name="text" cols="40" rows="5" value={notification?.text}>
        </textarea>
      </div> */}
        <TextEditor
          value={notification.text}
          onChange={handleTextChange}
        />

    </div>
  )
}

export default NotificationsUpdate
