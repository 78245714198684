
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import url from '../../url';
import TextEditor from '../../components/TextEditor';

function NewsUpdate() {

  const notificationId = useLocation().pathname.split("/")[3]

  const [notification, setNotification] = useState({
    cnap_kontacts: "",
  });


  const [notificationImage, setNotificationImage] = useState({
    image: "",
  });



  const UploadImages = async date => {
    //console.log(selectedFile)
    const formData = new FormData();
    for (let i = 0; i < selectedFile.length; i++) {
      //formData.append('files', selectedFile[i]);
      formData.append("files", selectedFile[i], date + "_" + (i + 1) + '.png')
    }
    //console.log(...formData)
    try {
      await axios.post(url + "/image", formData, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
    } catch (err) {

    }
  }




  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFileUrl, setSelectedFileUrl] = useState([]);
  const changeHandler = (event) => {
    //console.log(...event.target.files)
    if (event.target.files.length > 0) {
      const urls = []
      for (let i = 0; i < event.target.files.length; i++) {
        urls[i] = URL.createObjectURL(event.target.files[i])
      }
      const NewFiles = [...selectedFile.concat(...event.target.files)]
      setSelectedFile(NewFiles);
      setSelectedFileUrl(selectedFileUrl.concat(urls))
    }
  };

  function RemoveFromArray(arrayI, thisIndex) {
    const newArr = [];
    for (let i = 0; i < arrayI.length; i++) {
      if (i !== thisIndex)
        newArr.push(arrayI[i])
    }
    return newArr
  }

  const Hendledelete = (event) => {
    const indexx = selectedFileUrl.indexOf(event.target.name);
    setSelectedFile(RemoveFromArray([...selectedFile], indexx));
    setSelectedFileUrl(RemoveFromArray(selectedFileUrl, indexx));
  };


  useEffect(() => {
    const fecthAllnotifications = async () => {
      try {
        const res = await axios.get(url + '/getCnapKontacts', {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        })
        setNotification(res.data[0])
      } catch (err) {
        console.log(err)
      }
    }
    fecthAllnotifications()
  }, [notificationId])

  const HandleChange = (e) => {
    setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const navigate = useNavigate();

  const handleClick = async e => {
    e.preventDefault()
    async function save() {
      try {
        const res = await axios.put(url + "/updKontacts", {"cnap_kontacts": notification.cnap_kontacts.replaceAll("<div>",'').replaceAll("</div>",'<br>').replaceAll("<br><br><br>",'<br><br>')}, {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        });
        navigate(-1)
      } catch (err) {

      }
    }
    await save();
  }
  const updateImage = async e => {
    for (let i = 0; i < selectedFile.length; i++)
      notificationImage.image += ' ' + (i + 1)

    try {
      const res = await axios.post(url + "/gromada_info_image", notificationImage, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
      if (typeof res.data === "number")
        UploadImages(res.data).then(navigate(-1));
    } catch (err) {
      console.log(err)
      alert('Error message: ' + err);
    }

  }

  function onChange(e) {
    setNotification(prev => ({ ...prev, ["cnap_kontacts"]: e.target.value }))
  }


  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
        <button onClick={updateImage} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Оновити зображення</button>
        <button onClick={handleClick} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Оновити текст</button>
      </div>
      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        <label htmlFor="file-upload" className="custom-file-upload">
          +
        </label>
        <input id="file-upload" type="file" name="file" onChange={changeHandler} multiple accept="image/png, image/gif, image/jpeg" />
        <div className="ImageDiv">
          {selectedFileUrl?.map(file => (
            <div key={file} className='ImageWrapper'>
              <img src={file} alt=""></img>
              <button onClick={Hendledelete} name={file} className='DeleteImg'>  </button>
            </div>
          ))}
        </div>
      </div>
      <div className='ImputWrapper'>Контакти
        {/* <textarea onChange={HandleChange} name="cnap_kontacts" cols="40" rows="5" value={notification.cnap_kontacts}></textarea> */}
        <TextEditor
          value={notification.text}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default NewsUpdate
