
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import {useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import url from '../../url';
import TextEditor from '../../components/TextEditor';



function NotificationsUpdate() {

  let char_id = useLocation().pathname.split("/")[2]
  let notificationId = useLocation().pathname.split("/")[4]

  const [notification, setNotification] = useState({
    text: "",
  });
  
  useEffect(() => {
    const fecthAllnotifications = async () => {
      try {
        const res = await axios.get(url + '/dailyGetText/' + notificationId+char_id, {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        })
        //console.log(JSON.parse(res.data))
        setNotification({text:JSON.parse(res.data)})
      } catch (err) {
        console.log(err)
      }
    }
    fecthAllnotifications()
  }, [notificationId])

  const HandleChange = (e) => {
    setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const navigate = useNavigate();

  const handleClick = async e => {
    e.preventDefault()
    try {
      await axios.put(url + "/dailyUppdateText/" + notificationId + char_id, {"text": notification.text.replaceAll("<div>",'').replaceAll("</div>",'<br>').replaceAll("<br><br><br>",'<br><br>')}, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
      navigate(-1)
    } catch (err) {

    }
  }
  function onChange(e) {
    setNotification(prev => ({ ...prev, ["text"]: e.target.value }))
  }

  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
          <button onClick={handleClick} className='AddEvent' style={{backgroundColor:"#0F6C00", color:"#fff"}}>Зберегти</button>
      </div>
      <div className='ImputWrapper'>Текст

      <TextEditor
          value={notification.text}
          onChange={onChange}
        />
      {/* <textarea onChange={HandleChange} name="text" cols="40" rows="5" value={notification?.text}></textarea> */}
      </div>
    </div>
  )
}

export default NotificationsUpdate
